import { Link } from "gatsby";
import React from "react"
import { Button } from "react-bootstrap";
import { smoothScrollToTop } from "../utils/anchor-helpers";

export default function Footer() {
  return (
    <>
      <footer id="footer" className="container mt-5 py-5 border-top">
        <p className="float-end">
          <Button variant="link" onClick={(e) => smoothScrollToTop(e)}>Back to top</Button>
        </p>
        <p className="mb-0">
          {/*
            Even though we're building a static site, the year output below will always be
            dynamically evaluated on the client side.
          */}
          &copy; {new Date().getFullYear()} Wednesday Night Games, LLC
          <br />
          <Link to="/tos-eula">Terms of Service / EULA</Link>
          <br />
          <Link to="/privacy-policy">Privacy Policy</Link>
        </p>
      </footer>
    </>
  );
}
